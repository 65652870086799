import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatestLoanPaymentResponse } from 'api/ApplicationDataApi';
import { getLatestLoanPayment } from 'thunks';

export interface LatestLoanPaymentState {
  isLoading: boolean;
  latestPayment?: LatestLoanPaymentResponse;
  error?: boolean;
}

export const initialState: LatestLoanPaymentState = {
  isLoading: false,
};

const lastLoanPayment = createSlice({
  name: 'latestLoanPayment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLatestLoanPayment.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(getLatestLoanPayment.fulfilled, (state, { payload }: PayloadAction<LatestLoanPaymentResponse>) => {
      state.latestPayment = payload;
      state.isLoading = false;
      state.error = false;
    });
    builder.addCase(getLatestLoanPayment.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

export default lastLoanPayment.reducer;
