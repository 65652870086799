import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApplicationDataApi } from 'api/ApplicationDataApi';

import { ErrorResponse } from './ErrorResponse';

export const createGetLatestLoanPaymentThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('loanManagement/latestPayment', async (applicationId: string, thunkApi) => {
    try {
      return await api.getLatestLoanPayment(applicationId);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);
      throw error;
    }
  });
