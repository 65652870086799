import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { FlowComponentType } from 'routes/types';
import { MissedPaymentResult } from 'enums/FlowNextResults';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getLatestLoanPayment } from 'thunks';

import { RootState } from 'handlers';

import { generateHeaderContent, PaymentAmountStatus } from './generateHeaderContent';

import styles from './MissedPayment.module.scss';

const MissedPayment = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }
  const { isLoading: isLoadingLatestPayment, latestPayment, error } = useSelector(
    (state: RootState) => state.latestLoanPayment,
  );
  const { missedPaymentAmount, amountPerPaycheck, missedPaymentDate, missedPaymentReason } = application;
  const missedPaymentAmountValue = missedPaymentAmount ?? 0;
  const amountPerPaycheckValue = amountPerPaycheck ?? 0;
  const date = new Date(missedPaymentDate!);

  const paymentAmountStatus = (() => {
    if (missedPaymentAmountValue > amountPerPaycheckValue) {
      return PaymentAmountStatus.Exceeded;
    }
    if (missedPaymentAmountValue === amountPerPaycheckValue) {
      return PaymentAmountStatus.EqualToPaycheck;
    }
    return PaymentAmountStatus.Normal;
  })();

  const getHeaderContent = generateHeaderContent({
    isLoadingLatestPayment,
    paymentAmountStatus,
    latestPayment,
    missedPaymentAmountValue,
    missedPaymentDate: date,
  });

  useEffect(() => {
    if (error) {
      handleNext(MissedPaymentResult.Error);
    }
  }, [error]);

  useEffect(() => {
    (async () => {
      if (!latestPayment && paymentAmountStatus === PaymentAmountStatus.Normal) {
        dispatchWithUnwrap(getLatestLoanPayment(application.id));
      }
    })();
  }, [paymentAmountStatus]);

  const handleContinue = () => {
    if (missedPaymentAmountValue >= amountPerPaycheckValue && !missedPaymentReason) {
      return handleNext(MissedPaymentResult.CollectReason);
    }
    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title={getHeaderContent.title}
        subtitle={
          <div className={styles.textContainer}>
            <p>{getHeaderContent.subtitle}</p>
            <p>
              {paymentAmountStatus === PaymentAmountStatus.Normal
                ? `Let’s work together to resolve this balance. You can make a one-time payment to cover the rest.`
                : 'We didn’t receive this via direct deposit. Let’s work together on getting you back on track with your loan.'}
            </p>
          </div>
        }
      >
        <Button onClick={handleContinue}>Continue</Button>
      </FormContainer>
      <div className={styles.disclaimerContainer}>
        <p>
          We may report information about your account to credit bureaus. Late payments, missed payments, represented
          payments, or other defaults on your account may be reflected in your credit report. This is an attempt to
          collect debt, and any information may be used for that purpose.
        </p>
      </div>
    </>
  );
};

export default MissedPayment;
