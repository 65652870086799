import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import Button from 'components/Button';
import { FlowComponentType } from 'routes/types';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import FormContainer from 'components/LoanForm/FormContainer';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import { CurrentFlow } from 'enums/CurrentFlow';
import { setApplicationFlow } from 'handlers/applicationStep';

import styles from './PaymentInitiated.module.scss';

const PaymentInitiated = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const dispatch = useDispatch();
  const { application } = useSelector(getApplicationData);

  if (!application) {
    throw new Error('Missing application data');
  }

  const { missedPaymentAmount, bankName } = application;

  const handleContinue = () => {
    dispatch(setApplicationFlow({ currentFlow: CurrentFlow.FinancialCheckup }));
    handleNext();
  };

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<CheckIcon />}
        title={
          <>
            Thanks. We’ve initiated a payment of {formatMonetaryAmount(missedPaymentAmount, true)} from your {bankName}{' '}
            account.
          </>
        }
        subtitle="Funds may take up to 3 business days to transfer. We will keep you updated on its progress."
      >
        <div className={styles.nextStepContainer}>
          <div>
            <p className={styles.header}>What's Next</p>
            <span className={styles.subtitle}>See how else Plannery can help you save money. </span>
          </div>
          <Button className={styles.nextButton} onClick={handleContinue}>
            See Your Finances
          </Button>
        </div>
      </FormContainer>
    </div>
  );
};

export default PaymentInitiated;
