import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import FormNavigation from 'components/FormNavigation';
import Button from 'components/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import { FlowComponentType } from 'routes/types';
import { getApplicationData } from 'selectors/getApplicationData';
import { updateApplicationLoanManagementData } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import styles from './MissedPaymentReason.module.scss';

enum MissedReason {
  FMLA = 'I’m on family and medical leave (FMLA)',
  ChangedJobs = "I've changed jobs",
  LostJob = "I've lost my job",
  PayrollDisconnected = 'I wasn’t aware that my payroll was disconnected',
  FinancialStruggle = 'I’m struggling to cover my expenses',
  Other = 'Other (please specify)',
}

const MissedPaymentReason = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  // skip this page if we already know the reason
  useEffect(() => {
    if (application.missedPaymentReason) {
      handleNext();
    }
  }, []);

  const [selected, setSelected] = React.useState<MissedReason>();
  const [otherReason, setOtherReason] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState(false);

  const selectOptions = Object.keys(MissedReason).map((key) => ({
    label: MissedReason[key as keyof typeof MissedReason],
    value: key,
  }));

  const getKeyFromValue = (value: MissedReason): keyof typeof MissedReason | undefined =>
    Object.keys(MissedReason).find((key) => MissedReason[key as keyof typeof MissedReason] === value) as
      | keyof typeof MissedReason
      | undefined;

  const isOtherReason = selected === getKeyFromValue(MissedReason.Other);

  const handleContinue = async () => {
    setIsLoading(true);
    await dispatchWithUnwrap(
      updateApplicationLoanManagementData({
        applicationId: application!.id,
        missedPaymentReason: isOtherReason ? otherReason : selected,
      }),
    );
    setIsLoading(false);
    handleNext();
  };

  return (
    <>
      <FormNavigation showBackLink={navigationInfo.showBackLink} title={navigationInfo.title} />
      <FormContainer
        title="Reason for Missed Payment"
        subtitle={
          <div className={styles.text}>
            What was the reason you missed your payment? This will help us better understand your situation.
          </div>
        }
      >
        <div className={styles.inputContainer}>
          <InputSelect
            label="Reason"
            className={styles.reasonSelect}
            placeholder="Select reason"
            options={selectOptions}
            value={selected}
            onChange={(option) => setSelected(option.value as MissedReason)}
          />
          {isOtherReason && (
            <Input
              placeholder="Please specify"
              className={styles.reasonInput}
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          )}
        </div>
        <Button
          className={styles.nextButton}
          onClick={handleContinue}
          disabled={!selected || (isOtherReason && otherReason.trim() === '')}
          isLoading={isLoading}
        >
          Continue
        </Button>
      </FormContainer>
    </>
  );
};

export default MissedPaymentReason;
