import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData as applicationData } from 'selectors/getApplicationData';
import { FlowComponentType } from 'routes/types';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { RootState } from 'handlers';
import { createDocuSignEnvelope } from 'thunks';
import { DocuSignDocument } from 'api/DocuSignEnvelopeApi';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import Loader from 'components/Loader';

import { ReactComponent as SignIcon } from 'images/sign-icon.svg';

import styles from './Authorization.module.scss';

const Authorization = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application, isLoading: isLoadingApplication } = useSelector(applicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const { hasSignedACHForms, bankName } = application;

  const { isLoading } = useSelector((state: RootState) => state.docuSignEnvelope);

  useEffect(() => {
    if (!isLoadingApplication && hasSignedACHForms) {
      handleNext();
    }
  }, [isLoadingApplication, application]);

  const handleCreateDocuSignEnvelopment = async () => {
    if (application) {
      const applicationId = application.id;
      window.location.replace(
        await dispatchWithUnwrap(
          createDocuSignEnvelope({
            applicationId,
            windowLocationHref: window.location.href,
            document: DocuSignDocument.ACH,
          }),
        ),
      );
    }
  };

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title="Authorization"
        icon={<SignIcon />}
        subtitle={
          <div className={styles.subtitleContainer}>
            {isLoadingApplication ? (
              <Loader color="#9d86f9" size={25} />
            ) : (
              <p>We need to receive your authorization before we can transfer money from your {bankName} account.</p>
            )}
            <p>Click "Sign Authorization" below to be redirected to DocuSign.</p>
          </div>
        }
      >
        <Button onClick={handleCreateDocuSignEnvelopment} isLoading={isLoading}>
          Sign Authorization
        </Button>
      </FormContainer>
    </div>
  );
};

export default Authorization;
