import React, { FC } from 'react';
import clsx from 'clsx';

import BackToWebsite from 'components/BackToWebsite';
import { ReactComponent as ChatIcon } from 'images/chat-icon.svg';
import { ReactComponent as EmailIcon } from 'images/email-icon.svg';

import styles from './Contact.module.scss';

interface ContactProps {
  handleGetBack?: () => void;
  showBackButton?: boolean;
  className?: string;
  isHorizontalCard?: boolean;
}

const Contact: FC<ContactProps> = ({ handleGetBack, showBackButton = true, className, isHorizontalCard = false }) => (
  <>
    <div className={clsx(styles.contactsContainer, className)}>
      <a
        href="sms:(404) 205-8882"
        className={clsx(styles.contactItemContainer, isHorizontalCard && styles.horizontalContactItem)}
      >
        <ChatIcon />
        <div>
          <p className={clsx(styles.contactsTitle, isHorizontalCard && styles.horizontalContactsTitle)}>
            Text or call us
          </p>
          <p className={styles.contactsSubtitle}>(404) 205-8882</p>
        </div>
      </a>
      <a
        href="mailto:support@planneryapp.com"
        className={clsx(styles.contactItemContainer, isHorizontalCard && styles.horizontalContactItem)}
      >
        <EmailIcon />
        <div>
          <p className={clsx(styles.contactsTitle, isHorizontalCard && styles.horizontalContactsTitle)}>Email us</p>
          <p className={styles.contactsSubtitle}>Typically respond within 24-48 hours</p>
        </div>
      </a>
    </div>
    {showBackButton && <BackToWebsite onClick={handleGetBack} className={styles.button} />}
  </>
);

export default Contact;
