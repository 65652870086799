import React from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import Contact from 'components/Contact';
import { DEFAULT_LATE_FEE, LATE_FEE_DAYS } from 'components/MissedPayments/constants';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { addDays, formatTextDate } from 'utils/dateUtils';
import { FlowComponentType } from 'routes/types';
import { RootState } from 'handlers';

import { ReactComponent as RoadIcon } from 'images/road-sign.svg';

import styles from './PaymentFailed.module.scss';

const PaymentFailed = ({ navigationInfo }: FlowComponentType) => {
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const { missedPaymentAccountBalance } = useSelector((state: RootState) => state.bankAccount);
  const { missedPaymentAmount, missedPaymentDate, lateFee, bankName } = application;

  const date = new Date(missedPaymentDate!);
  const dateWithAddedDays = addDays(date, LATE_FEE_DAYS);
  const hasDatePassed = dateWithAddedDays < new Date();

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<RoadIcon />}
        title="That’s a bummer"
        subtitle={
          <p className={styles.text}>
            You don’t have enough funds in your {bankName} account to make this payment. We see that your available
            balance is {formatMonetaryAmount(missedPaymentAccountBalance)}, but you need at least{' '}
            {formatMonetaryAmount(missedPaymentAmount, true)}.
          </p>
        }
      >
        <div className={styles.contactContainer}>
          <span className={styles.title}>
            {hasDatePassed ? 'Please contact us right away!' : 'Please contact us right away to avoid a late fee.'}
          </span>
          <p className={styles.lateFeeText}>
            A {formatMonetaryAmount(lateFee || DEFAULT_LATE_FEE)} late fee {hasDatePassed ? 'was' : 'will be'} charged
            on {formatTextDate(dateWithAddedDays)}.
          </p>
          <Contact showBackButton={false} />
        </div>
      </FormContainer>
    </div>
  );
};

export default PaymentFailed;
