import React from 'react';

import { ReactComponent as PlanneryLogo } from 'images/plannery.svg';

import styles from './PartnerBanner.module.scss';

interface PartnerBannerProps {
  name: string;
  logo: JSX.Element;
}

const PartnerBanner = ({ name, logo }: PartnerBannerProps) => (
  <div className={styles.container}>
    <div className={styles.companyCard}>
      <PlanneryLogo className={styles.companyLogo} />
      <p className={styles.planneryName}>Plannery</p>
    </div>
    <div className={styles.companyCard}>
      {logo}
      <p className={styles.companyName}>{name}</p>
    </div>
  </div>
);

export default PartnerBanner;
