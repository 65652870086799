import React, { useCallback, useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';

import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  PlaidLinkError,
  PlaidLinkOnEvent,
  PlaidLinkStableEvent,
  PlaidLinkOnEventMetadata,
} from 'react-plaid-link';
import {
  getLinkToken,
  setBankAccounts,
  getCheckingAccounts,
  finishPlaidIncomeVerification,
  getApplicationData,
} from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';

import Button from 'components/Button';

import { setPublicToken } from 'handlers/plaidPublicToken';
import { ReactComponent as Transfer } from 'images/transfer.svg';
import { ReactComponent as BlueTransfer } from 'images/blue-transfer-icon.svg';
import { ReactComponent as Profile } from 'images/profile.svg';
import { ReactComponent as Secure } from 'images/lock.svg';
import { ReactComponent as PlaidLogo } from 'images/plaid.svg';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { ReactComponent as PlaidIdentityIcon } from 'images/plaid-identity.svg';
import { ReactComponent as MoneyIcon } from 'images/yellow-money-icon.svg';
import { StepComponent } from 'components/Steps/stepUtils';
import FormContainer from 'components/LoanForm/FormContainer';
import PartnerBanner from 'components/Common/PartnerBanner';
import BankAccount from 'components/Verification/Steps/CheckingAccount/BankAccount';
import SelectAccount from 'components/Verification/Steps/CheckingAccount/SelectAccount';
import UserSessionWarning from 'components/Common/UserSessionWarning/UserSessionWarning';
import { VerificationStep, useVerificationSteps } from 'components/Verification/verificationSteps';
import { ButtonType } from 'components/Button/Button';
import useCurrentFlow from 'hooks/useCurrentFlow';
import FormNavigation from 'components/FormNavigation';
import { StepsResult, CheckingAccountResult } from 'enums/FlowNextResults';
import { SetBankAccountsError, SetBankAccountsErrorType } from 'api/PlaidApi';
import { isPlaidTokenValid } from 'utils/isPlaidTokenValid';

import styles from './Plaid.module.scss';

const descriptionItems = [
  {
    description: 'Retrieves your account and routing number for depositing your funds and making payments.',
    missedPaymentDescription: 'Make your loan payment with one click. No typos or long numbers to enter.',
    icon: Transfer,
    missedPaymentIcon: PlaidIdentityIcon,
  },
  {
    description: 'Verifies your identity and income.',
    missedPaymentDescription: 'Ensure there are funds in the account to avoid overdraft fees.',
    icon: Profile,
    missedPaymentIcon: MoneyIcon,
  },
  {
    description: null,
    missedPaymentDescription: 'Cancel authorization anytime.',
    icon: BlueTransfer,
    missedPaymentIcon: null,
  },
  {
    description: 'Uses bank level security protocols.',
    missedPaymentDescription: 'Uses bank level security protocols.',
    icon: Secure,
    missedPaymentIcon: null,
  },
];

enum PlaidEvent {
  BANK_INCOME_INSIGHTS_COMPLETED = 'BANK_INCOME_INSIGHTS_COMPLETED',
  HANDOFF = 'HANDOFF',
}

enum PlaidAccountError {
  ACCOUNT_OWNER_MISMATCH_ERROR = 'ACCOUNT_OWNER_MISMATCH_ERROR',
  OTHER = 'OTHER',
}

const PlaidLink = ({ handleNext }: StepComponent): JSX.Element => {
  const [token, setToken] = useState<string | null>(null);
  const [exitedPlaid, setExitedPlaid] = useState<boolean>(false);
  const [plaidCompleted, setPlaidCompleted] = useState<boolean>(false);
  const [plaidAccountError, setPlaidAccountError] = useState<PlaidAccountError | null>(null);
  // @ts-ignore-next-line
  const [incomeCompleted, setIncomeCompleted, incomeCompletedRef] = useStateRef<boolean>(false);

  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { isMissedPaymentFlow } = useCurrentFlow();
  const { stepsProgress: verificationStepsStatus, isLastStep } = useVerificationSteps();
  const { application } = useSelector((state: RootState) => state.applicationData!);

  const applicationId = application?.id;

  useEffect(() => {
    if (plaidCompleted && plaidAccountError) {
      setPlaidCompleted(false);
    }
  }, [plaidAccountError]);

  useEffect(() => {
    let mounted = true;

    if (!plaidCompleted && application?.plaidTokenLastUpdated && isPlaidTokenValid(application.plaidTokenLastUpdated)) {
      if (isMissedPaymentFlow) {
        if (application.hasSignedACHForms) {
          return handleNext(CheckingAccountResult.MakePayment);
        }
        return handleNext(CheckingAccountResult.Continue);
      }
      return;
    }

    const createLinkToken = async () => {
      const response = await dispatchWithUnwrap(getLinkToken(applicationId!));
      const { LINK_TOKEN: linkToken } = response;
      mounted && setToken(linkToken);
    };

    createLinkToken();

    return () => {
      mounted = false;
    };
  }, [application]);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (plaidPublicToken: string) => {
    try {
      setPlaidAccountError(null);
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      await dispatchWithUnwrap(setBankAccounts({ publicToken: plaidPublicToken, applicationId: applicationId! }));
    } catch (error: any) {
      const response = error as SetBankAccountsError;

      if (response.errorType === SetBankAccountsErrorType.AccountOwnerMismatchError) {
        return setPlaidAccountError(PlaidAccountError.ACCOUNT_OWNER_MISMATCH_ERROR);
      }
      return setPlaidAccountError(PlaidAccountError.OTHER);
    }
    if (incomeCompleted || incomeCompletedRef.current) {
      dispatch(finishPlaidIncomeVerification(applicationId!));
    }
    dispatch(getCheckingAccounts({ applicationId: applicationId!, publicToken: plaidPublicToken }));
    dispatch(setPublicToken({ publicToken: plaidPublicToken }));
    dispatchWithUnwrap(getApplicationData(applicationId!));
    analytics.track('Checking Account Connected through Plaid');
  }, []);

  const handleEvent = useCallback<PlaidLinkOnEvent>(
    (evtName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
      analytics.track(`Plaid Event: ${evtName}`, {
        metadata,
      });

      switch (evtName) {
        case PlaidEvent.BANK_INCOME_INSIGHTS_COMPLETED:
          setIncomeCompleted(true);
          break;
        case PlaidEvent.HANDOFF:
          setPlaidCompleted(true);
          break;
        default:
          break;
      }
    },
    [],
  );

  const handleExit = (error: PlaidLinkError | null) => {
    analytics.track('Plaid Link Closed', {
      ...(error && { error }),
    });
    setExitedPlaid(true);
  };

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onExit: handleExit,
    onEvent: handleEvent,
  };

  const { open, ready } = usePlaidLink(config);

  if (exitedPlaid) {
    return <BankAccount handleNext={handleNext} />;
  }

  if (plaidCompleted) {
    return <SelectAccount handleNext={handleNext} />;
  }

  return (
    <>
      {isMissedPaymentFlow && <FormNavigation title="Missed Payment" />}
      <FormContainer
        title="Connect Your Checking Account"
        subtitle="Connect the account where you deposit your paycheck and pay bills."
      >
        <PartnerBanner name="Plaid" logo={<PlaidLogo />} />

        <div className={styles.descriptionItems}>
          {descriptionItems.map((item, index) => {
            if (!isMissedPaymentFlow && item.description === null) return null;
            return (
              <div className={styles.item} key={index}>
                {item.missedPaymentIcon && isMissedPaymentFlow ? (
                  <item.missedPaymentIcon className={styles.icon} />
                ) : (
                  <item.icon className={styles.icon} />
                )}
                <div className={styles.itemText}>
                  {isMissedPaymentFlow ? item.missedPaymentDescription : item.description}
                </div>
              </div>
            );
          })}
        </div>

        {verificationStepsStatus[VerificationStep.CheckingAccount] && !isMissedPaymentFlow ? (
          <div className={styles.verified}>
            <CheckIcon className={styles.checkIcon} />
            <p className={styles.verifiedLabel}>Account connected</p>
          </div>
        ) : (
          <div className={styles.buttonContainer}>
            {plaidAccountError && (
              <UserSessionWarning
                showContactUsOption
                message={
                  plaidAccountError === PlaidAccountError.ACCOUNT_OWNER_MISMATCH_ERROR
                    ? "The account owner's name does not match your name. Please verify your name and try again"
                    : 'Sorry, there was an unexpected error. Please try again'
                }
              />
            )}
            <Button onClick={() => open()} isLoading={!ready || !application}>
              {isMissedPaymentFlow ? 'Link My Account' : 'Next'}
            </Button>

            {!isMissedPaymentFlow && !isLastStep && (
              <Button onClick={() => handleNext(StepsResult.DoThisLater)} type={ButtonType.Secondary}>
                Do this later
              </Button>
            )}
          </div>
        )}
      </FormContainer>
    </>
  );
};

export default PlaidLink;
