import React from 'react';
import { LatestLoanPaymentResponse } from 'api/ApplicationDataApi';
import { formatTextDate } from 'utils/dateUtils';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import Loader from 'components/Loader';

import styles from './MissedPayment.module.scss';

export enum PaymentAmountStatus {
  Exceeded = 'exceeded',
  EqualToPaycheck = 'equalToPaycheck',
  Normal = 'normal',
}

interface HeaderContent {
  isLoadingLatestPayment: boolean;
  paymentAmountStatus: PaymentAmountStatus;
  missedPaymentAmountValue: number;
  missedPaymentDate: Date;
  latestPayment?: LatestLoanPaymentResponse;
}

const ACTION_REQUIRED_TITLE = 'Action Required: Payment Shortfall';
const MISSED_PAYMENT_TITLE = "You've Missed a Payment";

export const generateHeaderContent = ({
  isLoadingLatestPayment,
  paymentAmountStatus,
  latestPayment,
  missedPaymentAmountValue,
  missedPaymentDate,
}: HeaderContent) => {
  if (paymentAmountStatus === PaymentAmountStatus.Exceeded) {
    return {
      title: MISSED_PAYMENT_TITLE,
      subtitle: (
        <p>
          Your last payment was due on {formatTextDate(missedPaymentDate)}. You currently owe{' '}
          {formatMonetaryAmount(missedPaymentAmountValue, true)}.
        </p>
      ),
    };
  }
  if (paymentAmountStatus === PaymentAmountStatus.EqualToPaycheck) {
    return {
      title: MISSED_PAYMENT_TITLE,
      subtitle: (
        <p>
          Your last payment of {formatMonetaryAmount(missedPaymentAmountValue, true)} was due on{' '}
          {formatTextDate(missedPaymentDate)}.
        </p>
      ),
    };
  }

  const { amount, date: latestPaymentDate } = latestPayment ?? {};
  const latestPaymentDateConverted = new Date(latestPaymentDate!);

  return {
    title: ACTION_REQUIRED_TITLE,
    subtitle: isLoadingLatestPayment ? (
      <div className={styles.loaderContainer}>
        <Loader color="#9d86f9" size={25} />
      </div>
    ) : (
      <p>
        Your last payment of {formatMonetaryAmount(amount, true)} was received on{' '}
        {formatTextDate(latestPaymentDateConverted)}, but you still owe{' '}
        {formatMonetaryAmount(missedPaymentAmountValue, true)}.
      </p>
    ),
  };
};
