import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getApplicationData, updateApplicationBankAccountData } from 'thunks';
import { setBankAccountData } from 'handlers/bankAccount';
import { RootState } from 'handlers';
import { BankAccountVariable } from 'enums/LoanFormVariables';
import { BankAccountInputLabel } from 'enums/BankAccountInputLabel';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormContainer from 'components/LoanForm/FormContainer';
import NumberInput from 'components/NumberInput';
import Button from 'components/Button';
import Input from 'components/Input';
import { getApplicationData as applicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { CheckingAccountResult } from 'enums/FlowNextResults';
import { StepComponent } from 'components/Steps/stepUtils';

import styles from './BankAccount.module.scss';

const ALPHABETICAL_WITH_SPACE_PATTERN = /^[a-zA-Z ]*$/;
const ROUTING_NUMBER_LENGTH_PATTERN = /^.{9}$/;
const ACCOUNT_NUMBER_MAX_LENGTH = 50;
const ROUTING_NUMBER_MAX_LENGTH = 9;

const BankAccount = ({ handleNext }: StepComponent) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { isMissedPaymentFlow } = useCurrentFlow();
  const { application } = useSelector(applicationData);
  const { isLoading } = useSelector((state: RootState) => state.bankAccount);

  const defaultValues = useSelector((state: RootState) => state.bankAccount.bankAccountData);

  const {
    register,
    watch,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const watcher = watch();
  const disableAccountHolder = !!watcher[BankAccountVariable.AccountHolder];

  useEffect(() => {
    if (!watcher[BankAccountVariable.AccountHolder]) {
      setValue(BankAccountVariable.AccountHolder, `${application?.borrowerFirstName} ${application?.borrowerLastName}`);
      trigger(BankAccountVariable.AccountHolder);
    }

    register(BankAccountVariable.AccountHolder, {
      required: getMessageForRequiredFields(BankAccountInputLabel.AccountHolder),
      pattern: {
        message: getMessageForInvalidFields(BankAccountInputLabel.AccountHolder),
        value: ALPHABETICAL_WITH_SPACE_PATTERN,
      },
    });
    register(BankAccountVariable.BankName, {
      required: getMessageForRequiredFields(BankAccountInputLabel.BankName),
    });
    register(BankAccountVariable.RoutingNumber, {
      required: getMessageForRequiredFields(BankAccountInputLabel.RoutingNumber),
      pattern: {
        message: getMessageForInvalidFields(BankAccountInputLabel.RoutingNumber),
        value: ROUTING_NUMBER_LENGTH_PATTERN,
      },
    });
    register(BankAccountVariable.AccountNumber, {
      required: getMessageForRequiredFields(BankAccountInputLabel.AccountNumber),
    });
  }, [register, watcher]);

  const handleContinue = async () => {
    dispatch(setBankAccountData(watcher));
    await dispatchWithUnwrap(
      updateApplicationBankAccountData({
        applicationId: application!.id,
        bankAccountData: {
          [BankAccountVariable.AccountHolder]: watcher[BankAccountVariable.AccountHolder]!,
          [BankAccountVariable.BankName]: watcher[BankAccountVariable.BankName]!,
          [BankAccountVariable.RoutingNumber]: watcher[BankAccountVariable.RoutingNumber]!,
          [BankAccountVariable.AccountNumber]: watcher[BankAccountVariable.AccountNumber]!,
        },
      }),
    );
    dispatchWithUnwrap(getApplicationData(application!.id));

    handleNext(CheckingAccountResult.Continue);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    trigger(event.target.name as BankAccountVariable);
  };

  return (
    <>
      {isMissedPaymentFlow && <FormNavigation title="Bank Account" step={3} stepCount={5} />}
      <FormContainer
        title="Bank account"
        subtitle={
          isMissedPaymentFlow ? (
            <p>Enter your bank account information where you would like us to use to make payments.</p>
          ) : (
            <div className={styles.subtitleContainer}>
              <p>
                Enter your bank account information where you would like us to send the funds for debt consolidation.
              </p>
              <p>The bank account may be used as a backup payment method.</p>
            </div>
          )
        }
      >
        <div className={styles.inputs}>
          <Input
            disabled={disableAccountHolder}
            label={BankAccountInputLabel.AccountHolder}
            placeholder="Name of the account holder"
            errorMessage={errors[BankAccountVariable.AccountHolder]?.message}
            className={styles.formInput}
            name={BankAccountVariable.AccountHolder}
            onBlur={onBlur}
            onChange={(event) => {
              if (disableAccountHolder) return;
              setValue(BankAccountVariable.AccountHolder, event.target.value);
              trigger(BankAccountVariable.AccountHolder);
            }}
            value={watcher[BankAccountVariable.AccountHolder]}
          />
          <Input
            label={BankAccountInputLabel.BankName}
            placeholder="Bank Name"
            errorMessage={errors[BankAccountVariable.BankName]?.message}
            className={styles.formInput}
            name={BankAccountVariable.BankName}
            onBlur={onBlur}
            onChange={(event) => {
              setValue(BankAccountVariable.BankName, event.target.value);
              trigger(BankAccountVariable.BankName);
            }}
            value={watcher[BankAccountVariable.BankName]}
          />
          <NumberInput
            label={BankAccountInputLabel.RoutingNumber}
            maxLength={ROUTING_NUMBER_MAX_LENGTH}
            placeholder="Routing Number"
            errorMessage={errors[BankAccountVariable.RoutingNumber]?.message}
            className={styles.formInput}
            name={BankAccountVariable.RoutingNumber}
            onBlur={onBlur}
            onChange={(event) => {
              setValue(BankAccountVariable.RoutingNumber, event.target.value);
              trigger(BankAccountVariable.RoutingNumber);
            }}
            value={watcher[BankAccountVariable.RoutingNumber]}
            allowLeadingZeros
            dataNeuroLabel="bank--routing"
          />
          <NumberInput
            label={BankAccountInputLabel.AccountNumber}
            maxLength={ACCOUNT_NUMBER_MAX_LENGTH}
            placeholder="Account Number"
            errorMessage={errors[BankAccountVariable.AccountNumber]?.message}
            className={styles.formInput}
            name={BankAccountVariable.AccountNumber}
            onBlur={onBlur}
            onChange={(event) => {
              setValue(BankAccountVariable.AccountNumber, event.target.value);
              trigger(BankAccountVariable.AccountNumber);
            }}
            value={watcher[BankAccountVariable.AccountNumber]}
            allowLeadingZeros
            dataNeuroLabel="bank--account"
          />
        </div>
        <Button onClick={handleContinue} disabled={!isValid} isLoading={isLoading || !application}>
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default BankAccount;
