import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationData as applicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { DEFAULT_LATE_FEE, LATE_FEE_DAYS } from 'components/MissedPayments/constants';
import Button from 'components/Button';
import Contact from 'components/Contact';
import { FlowComponentType } from 'routes/types';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { addDays, formatTextDate } from 'utils/dateUtils';
import { isPlaidTokenValid } from 'utils/isPlaidTokenValid';
import { useNavigate } from 'hooks/useNavigate';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { RoutePath } from 'enums/Routes';
import { MakeAPaymentResult } from 'enums/FlowNextResults';
import { getApplicationData } from 'thunks';

import styles from './MakeAPayment.module.scss';

const MakeAPayment = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application } = useSelector(applicationData);

  useEffect(() => {
    if (application?.id) dispatchWithUnwrap(getApplicationData(application.id));
  }, []);

  if (!application) {
    throw new Error('Missing application data');
  }

  const { missedPaymentAmount, missedPaymentDate, lateFee, plaidTokenLastUpdated, hasSignedACHForms } = application;

  const date = new Date(missedPaymentDate!);
  const dateWithAddedDays = addDays(date, LATE_FEE_DAYS);
  const hasDatePassed = dateWithAddedDays < new Date();

  const handleContinue = () => {
    if (plaidTokenLastUpdated && isPlaidTokenValid(plaidTokenLastUpdated)) {
      if (hasSignedACHForms) {
        return handleNext(MakeAPaymentResult.MakePayment);
      }
      return handleNext(MakeAPaymentResult.Authorization);
    }
    handleNext(MakeAPaymentResult.PlaidConnection);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} onBackClick={() => navigate(RoutePath.MissedPayment)} />
      <FormContainer
        title="Make a Payment"
        subtitle={
          <div className={styles.textContainer}>
            <p className={styles.text}>
              Make a payment of {formatMonetaryAmount(missedPaymentAmount, true)}{' '}
              {hasDatePassed ? (
                'now.'
              ) : (
                <>
                  before {formatTextDate(dateWithAddedDays)} to avoid a{' '}
                  {formatMonetaryAmount(lateFee || DEFAULT_LATE_FEE)} late fee.
                </>
              )}
            </p>
            <p className={styles.text}>
              Staying current on your loan allows us to continue helping other healthcare professionals.
            </p>
          </div>
        }
      >
        <Button onClick={handleContinue}>Make a Payment</Button>
      </FormContainer>
      <div className={styles.contactContainer}>
        <span className={styles.contactTitle}>We’re Here to Help!</span>
        <p className={styles.contactSubtitle}>If you’re in financial distress, we can discuss other payment options.</p>
        <Contact className={styles.contact} isHorizontalCard showBackButton={false} />
      </div>
    </>
  );
};

export default MakeAPayment;
